.libraryItem {
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  margin: 0.3rem 0rem;
  cursor: pointer;
  background-color: #F2F2F2;
  border: 1px solid #F2F2F2;
  border-radius: 5px;
}

.libraryItem:hover {
  background-color: rgba(0, 123, 255, 0.2);
  border: 1px solid #137cbd;
}

.libraryItemHeading {
  margin: 0px;
  font-weight: 600;
  font-size: 1.1rem;
  word-wrap: break-word;
}

.libraryItemDescription {
  margin: 0px;
  font-weight: 300;
}
