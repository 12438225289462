.profileTransferContainer {
  display: flex;
  width: 100%;
  min-width: 500px;
  max-width: 1000px;
}

.contentContainer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-right: 20px;
}

.buttonContainer {
  display: flex;
  flex-direction: column;
}

.buttonContainer button {
  margin: 2px;
  width: 100px;
}

.name {
  font-size: 1.1rem;
  font-weight: 500;
  margin-bottom: 1px;
}

.transfer {
  color: #0086b7;
  margin-bottom: 0;
}
