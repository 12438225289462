.addMenu {
  padding: 5px;
}

.addMenuHeading {
  padding-top: 5px;
  text-align: center;
  margin: 0px;
  font-weight: 600;
  font-size: 1.1rem;
  word-wrap: break-word;
}

.addOptionIcon {
  margin-right: 1rem;
}

.addOption {
  display: flex;
  align-items: center;
  padding: 0.5rem;
  margin: 0.5rem 0.3rem;
  cursor: pointer;
  overflow: auto;
  background-color: #F2F2F2;
  border: 1px solid #F2F2F2;
  border-radius: 5px;
}

.addOption:hover {
  background-color: rgba(0, 123, 255, 0.2);
  border: 1px solid #137cbd;
}

.addOption:hover .addOptionHeading {
  color: #137cbd;
}

.addOption:hover .addOptionIcon {
  color: #137cbd;
}

.disabledOption {
  display: flex;
  align-items: center;
  padding: 0.5rem;
  margin: 0.5rem 0.3rem;
  overflow: auto;
  color: #ccc;
  cursor: not-allowed;
}

.addOptionHeading {
  margin: 0px;
  font-weight: 600;
  font-size: 1.1rem;
  word-wrap: break-word;
}

.addOptionDescription {
  margin: 0px;
  font-weight: 300;
}

.addOptionTextWrapper {
  overflow: auto;
}
