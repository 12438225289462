th {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 10;
  border-top: 0; 
}

.container {
  overflow: scroll;
  width: 100%;
  height: 100%
}