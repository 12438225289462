.tabsContainer {
  margin-bottom: -1px;
  border-bottom: 0;
  user-select: none;
}

.tab {
  padding: 10px 15px;
  border-top: 1px solid #ddd;
  border-left: 1px solid #ddd;
  border-bottom: 0;
  border-right: 0;
  cursor: pointer;
}

.tab:last-child {
  border-right: 1px solid #ddd;
  border-top: 1px solid #ddd;
  border-left: 1px solid #ddd;
  border-bottom: 0;
}

.tab:hover {
  background-color: #e4e4e4;
}

.activeTab {
  background-color: #fff;
  font-weight: bold;
  cursor: default;
}

.activeTab:hover {
  background-color: #fff;
  font-weight: bold;
}

.tabLink {
  font-size: 0.9rem;
  color: #048fc2;
}

.tabBadge {
  margin-left: 5px;
}
