.loginContainer {
  display: flex;
  width: 100vw;
  height: 100vh;
  background-color:#404040;
}

.leftPanelContainer {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  max-width: 400px;
  min-width: 300px;
  background-color: white;
  box-shadow: 0px 0px 10px 10px rgba(136,136,136,0.5)
}

.rightPanelContainer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 50px;
}

.leftPanelLogo {
  width: 350px;
  margin: 30px auto;
}

.loginForm {
  margin: 20px;
}
