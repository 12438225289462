.enableToggleContainer {
  display: flex;
  align-items: center;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.enableToggleContainer > p {
  margin-right: .5rem;
  margin-bottom: 0;
  font-weight: 600;
}

.generatingLoader {
  display: flex;
  flex-direction: column;
}

.generatingLoader > p {
  margin-top: 10px;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  color: rgb(33, 49, 60);
  letter-spacing: 0.03em;
}

.qrcode {
  margin: 10px 10px 20px 10px ;
}

.authenticatorCode {
  display: flex;
}

.authenticatorCode > button {
  margin-left: 0.5rem;
}
