.auditLogs {
  width:auto;
}
.auditLogs td {
  white-space:nowrap;
}
.auditLogs td:nth-last-child(2) {
  width:100%;
}
.dateFilter {
  outline: none;
}
