.profileTypeListContainer {
  height: auto;
  overflow-y: scroll;
}

.profileTypesContainer {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 1px
}

.profileTypesContainer > div {
  margin: 0 0 10px 0;
}

@media (min-width: 1300px) {
  .profileTypesContainer > div {
    margin-bottom: 10px;
    flex: 0 0 calc(50% - 5px);
  }
  .profileTypesContainer > div:nth-child(even) {
    margin-left: 5px;
  }
  .profileTypesContainer > div:nth-child(odd) {
    margin-right: 5px;
  }
}
