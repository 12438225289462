.widgetListContainer {
  list-style-type: none;
}

.widgetWrapperContainer {
  display: flex;
  flex-direction: column;
  width: 650px;
  height: 550px;
  margin-left: auto;
  margin-right: auto;
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid #e0e3ed;
}


.widgetHeader {
  display: flex;
  align-items: center;
  height: 25px;
  min-height: 25px;
  width: 100%;
  border-bottom: 1px solid #e0e3ed;
  padding-left: 15px;
  padding-right: 8px;
  cursor: grab;
  user-select: none;
}

.widgetHeader:hover {
  background-color: #d3e6ff;
}

.dragContainer {
  flex-grow: 1;
}

.headerTitle {
  margin: 0;
  font-weight: 500;
}

.widgetContent {
  display: flex;
  flex-grow: 1;
  height: 100%;
  justify-content: center;
  overflow-x: hidden;
  overflow-y: hidden;
}

.widgetContentScroll {
  overflow-y: scroll;
}

.deleteAlert {
  padding: 10px;
  font-weight: 500;
}

.deleteAlertButtons {
  display: flex;
  justify-content: flex-end;
}

.deleteAlertButtons button {
  margin-left: 5px;
}
