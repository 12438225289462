.profileListItem {
    cursor: default;
}

.profileListItem img {
    max-width: 100%;
    max-height: 100%;
}

/* Dirty hack to prevent over-engineering */
.profileListItem [class*="profileTopContainer"] {
    max-width: 130px;
    max-height: 130px;
}

.profileListItem:hover {
    background-color: #F2F2F2;
}

.dropzoneActive {
    background-color: rgba(0, 123, 255, 0.2);
}

.dropzone {
    height: 130px;
    max-width: 140px;
}