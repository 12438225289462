.gridFieldDisabled {
  color: #eeeeee;
  background-color: #00000011;
}

.gridFieldNotVisible {
  color: #eeeeee;
  background-color: #00000011;
}

.gridFieldReadonly {
  color: black;
  background-color: #00000011;
}