.searchdrawer__tablist .bp3-tab-list {
  display: flex;
  justify-content: space-around;
}

.searchdrawer__tablist .bp3-vertical .bp3-tab-list {
  justify-content: flex-start;
}

.searchdrawer__tablist .bp3-tab {
  outline: none;
}
