.profileContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  
  padding: 0.5rem;
  margin: 10px 10px 0px 10px;

  cursor: pointer;
  background-color: #F2F2F2;
  border: 1px solid #F2F2F2;
  border-radius: 5px;
}

.profileContainer:hover {
  background-color: rgba(0, 123, 255, 0.2);
}

.profileContainerLast {
  margin: 10px;
}

.profileName {
  margin: 0;
  font-size: 1rem;
  font-weight: 500;
}

.profileTypeLabel {
  margin-bottom: 2px;
}
.childrenContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin: 1px;
  border-style: none;
}

.profileScore {
  font-size: 0.9rem;
  font-weight: 500;
  margin: 0;
  margin-left: 10px;
  text-align: right;
}

.tagsContainer {
  margin-left: -2px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.field {
  color: #343a40;
  border-bottom: 1px solid #e6e6e6;
}

.fieldName {
  text-align: left;
}

.fieldValue {
  text-align: right;
}
