.table {
  width: 100%;
  background-color: white;
}

.headerCell {
  padding-top: 4px;
  padding-bottom: 4px;
}

.row:nth-child(even) {background-color: #fafafa;}

.ratingSystemHeader {
  padding-left: 5px;
}

.ratingSystemItem {
  text-align: center;
}