.checkOrderInfoContainer {
    margin: 10px;
  }
  
  .sectionContainer {
    margin-bottom: 10px;
  }
  
  .sectionHeading {
    font-size: 1.1rem;
    font-weight: 600;
  }
  
  .checksList {
    display: flex;
    justify-content: space-between;
    margin: 0 10px;
  }
  
  .dbsStatusTable {
    border-spacing: 5px 2px;
    border-collapse: initial;
    width: 100%;
  }

  .dbsStatusTable td {
    vertical-align: top;
  }

  .loader {
    display: flex;
    justify-content: center;
  }
  