.documentIcon {
  cursor: pointer;
  display: flex;
}

.iconWrapper {
  display: flex;
  justify-content: space-evenly;
  height: 100%;
}
