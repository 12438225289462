.header {
	background-color: #404040;
	padding: 0px 8px 0px 8px;
}

.app_nav__link, .app_nav__link:hover {
	margin-bottom: auto;
	color: #fff;
	display: inline-block;
	height: 26px;
	font-size: 14px;
	font-weight: 400;
	padding: 13px 15px 31px
}

.app_nav__link_active, .app_nav__link_active:hover {
	background-color: #fff;
	color: #0379ab;
	font-weight: 600;
}

.navLink {
	border: 0px;
	color: #fff!important;
	padding: 0px!important;
}

.navBarContainer > * {
	margin-left: 16px!important;
}

.navLink:hover {
	color: #fff;
}

.navLink:active {
	background-color: transparent !important;
	box-shadow: none !important;
}

.navBar a {
	color: #212529;
}

.logoContainer {
	min-width: 250px;
	min-height: 52px;
}
