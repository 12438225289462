.iconWrapper {
  display: flex;
  justify-content: center;
  height: 100%;
  align-items: center;
}

.tags > *:not(:first-child) {
  margin-left: 5px;
}

.documentIcon {
  height: 28px;
}