
.profileTopContainer {
  position: relative;
  width: 200px;
  height: 200px;
  margin: 20px auto 10px auto;
  background-color: transparent;
}

.profileContainer {
  position: relative;
}

.profileImageContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
 
.profileImage {
  display: block;
  min-width: 50px;
  min-height: 50px;
  max-width: 200px;
  max-height: 200px;
  height: auto;
  border-radius: 5px;
}

.editButton {
  position: absolute;
  right: 5px;
  bottom: 5px;
}

.dropzone {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 60px;
  margin-top: 60px;
  margin: 20px auto 10px auto;
  width: 200px;
  height: 200px;
}

.dropzoneText {
  margin-top: 10px;
  font-weight: 500;
  flex-grow: 1;
}
