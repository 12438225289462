.headerContainer {
  display: flex;
  flex-direction: column;
}

.headerOrganisationName {
  text-transform: uppercase;
  font-weight: 500;
  margin: 0;
  padding: 0;
}

.selectorCurrent {
  margin: 0;
}