.editorHeader {
  margin-bottom: 0px;
}

.editorContainer {
  overflow-y: scroll;
  height: 100%;
  padding: 3px;
}

.headerContainer {
  display: flex;
  justify-content: space-between;
  font-weight: 500;
  font-size: 1.2rem;
  cursor: pointer;
  margin-bottom: 5px;
}

.fieldContainer {
  display: flex;
  justify-content: space-between;
  background-color: white;
  border: 2px solid #F2F2F2;
  border-radius: 5px;
  padding: 5px;
  margin-bottom: 5px;
  user-select: none;
}

.fieldDisabled {
  background-color: #e6e6e6;
}

.fieldDeleted {
  border: 2px solid red;
}

.enabledContainer:hover {
  background-color: rgba(0, 123, 255, 0.2);
  cursor: pointer;
}

.selectedContainer {
  background-color: rgba(0, 123, 255, 0.2);
}

.fieldContent {
  display: flex;
  align-items: center;
}

.fieldContentDisabled {
    color: #848484
}

.fieldImageContainer {
  height: 48px;
  width: 48px;
  margin-right: 8px
}

.fieldImage {
  height: 48px;
  width: 48px;
}

.dragHandle {
  color: #848484;
  font-size: 1.1rem;
  margin: auto 5px auto 10px;
  cursor: move;
}
