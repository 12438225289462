.galleryContainer {
  max-height: 95%;
  max-width: 100%;
}

.card {
  border-radius: 3px;
  background-color: #ffffff;
  box-shadow: 0 0 0 1px rgb(16 22 26 / 15%), 0 0 0 rgb(16 22 26 / 0%), 0 0 0 rgb(16 22 26 / 0%);
  margin-bottom: 30px;
}

.cardBody {
  padding: 20px;
}

.divider {
  border-right: 1px solid rgba(16, 22, 26, 0.15);
  border-bottom: 1px solid rgba(16, 22, 26, 0.15);
}

.cardTitle {
  padding: 20px;
  display:flex;
  flex-direction: row;
}

.titleText {
  margin-top: .3rem;
  font-family: inherit;
  font-weight: 500;
  font-size: 1.5em;
}

.subText {
  margin-top: .3rem
}
