.select {
    min-width: 200px;
}


.cardConditionGroup {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px;
  background-color: #eeeeee;
  margin-top: 5px;
}