.list {
  background-color:white;
  display: grid;
  grid-template-columns: repeat(3, 1fr);

  @media (min-width: 1024px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (min-width: 1200px) {
    grid-template-columns: repeat(5, 1fr);
  }

  @media (min-width: 1400px) {
    grid-template-columns: repeat(6, 1fr);
  }

  @media (min-width: 1600px) {
    grid-template-columns: repeat(7, 1fr);
  }

  @media (min-width: 2000px) {
    grid-template-columns: repeat(8, 1fr);
  }
}

.listBorders {
  border: 1px solid #d9dddd;
}

.listItem {
  display: flex;
  margin-bottom: 10px;
}
