

.dragHandle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 2px;
  cursor: move;
}

.dragHandleIcon {
  color: #848484;
  font-size: 1.3rem;
}

.systemContent {
  display: flex;
  width: 100%;
  padding: 15px;
  align-items: center;
}

.sortableHelper {
  z-index: 1000;
}

.bp3-control {
  margin-bottom: 0px;
}

.groupCollapse {
  margin-left: 56px;
}