body {
  margin: 0;
	padding: 0;
	font-size: 14px;
	font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
}

.btn-link {
  color: #048abb;
  font-size: 14px;
  font-weight: 500;
}

.cursor-pointer{
  cursor: pointer;
}

.dropdown-toggle.nav-link, .dropdown-toggle.nav-link:hover {
	color:#fff;
}

.ag-theme-alpine .ag-header-cell, .ag-theme-alpine .ag-header-group-cell {
	color: #048abb;
	font-weight: 700;
	font-size: 14px;
}

.bridgeUpper {
  background: url(data:image/svg+xmlbase64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgi…pZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==);
  background: linear-gradient(#fff,#f8f8f8);
  background-color: #f5f6f7;
  border-bottom: 1px solid #dadddf;
	padding-top: 30px;
}

.bridgeLower {
  display: flex;
  flex-direction: column;
  background: url(data:image/svg+xmlbase64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgi…pZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==);
  background: linear-gradient(#fff,#f8f8f8);
  background-size: 100% 200px;
  background-color: #f8f8f8;
  background-repeat: no-repeat;
  padding: 20px;
}

.ReactModal__Overlay {
	z-index: 1000;
}

div#hubspot-messages-iframe-container {
  bottom: -80px!important;
}
