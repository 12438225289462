.container th {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 10;
  border-top: 0; 
}

.container {
  overflow: scroll;
  width: 100%;
  height: 90%;
}

.button {
  display: block;
  margin-bottom: 0.25rem;
  text-wrap: nowrap;
}