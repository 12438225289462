.userContainer {
  width: 100%;
  display: flex;
  
  padding: 0.5rem;
  margin: 10px 10px 0px 10px;

  cursor: pointer;
  background-color: #F2F2F2;
  border: 1px solid #F2F2F2;
  border-radius: 5px;
}

.userContainer:hover {
  background-color: rgba(0, 123, 255, 0.2);
}

.organisationName {
  font-weight: 500; 
  margin-bottom: 1px;
}

.userName {
  white-space: nowrap;
  max-width: 200px;
  overflow:hidden;
  text-overflow: ellipsis;
  padding-right: 10px;
}

.userEmail {
  white-space: nowrap;
  max-width: 200px;
  overflow:hidden;
  text-overflow: ellipsis;
  padding-right: 10px;
}

.userOrganisationRoles {
  padding-left: 5px;
}

/* 
.profileName {
  margin: 0;
  margin-left: 2px;
  font-size: 1rem;
  font-weight: 500;
}

.scoresContainer {
  display: flex;
  flex-direction: column;
  margin: 5px;
}

.profileScore {
  font-size: 0.9rem;
  font-weight: 500;
  margin: 0;
  margin-left: 10px;
  text-align: right;
}

.tagsContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
} */
