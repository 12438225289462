.pickerOptionsContainer {
  width: 350px;
}

.profileTypeOption {
  display: flex;
  padding: 10px;
  align-items: center;
  cursor: pointer;
  user-select: none;
}

.profileTypeOption:hover {
  background-color: rgba(0, 123, 255, 0.2);
}

.profileTypeText {
  margin-left: 10px;
}

.profileTypeText > p{
  margin-bottom: 0;
}

.profileTypeName {
  font-weight: 500;
  margin-bottom: 5px;
  font-size: 1.1rem;
}

.profileTypeParentName {
  color: gray;
  font-size: 0.9rem;
  margin-left: 5px;
}

.profileTypeDescription {
  color: gray;
  margin-top: 5px;
  margin-left: 5px;
}

.popover {
  max-height: 500px;
  overflow-y: auto;
}