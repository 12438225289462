.selectorContainer {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.selectorText {
  margin-right: 10px;
  margin-bottom: 0;
  font-size: 1.2rem;
}
