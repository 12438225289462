.profileTypeContainer {
  border: 2px solid transparent;
  width: 100%;
  height: 120px;
  user-select: none;
}

.profileTypeDeleted {
  border-radius: 5px;
  border: 2px solid red;
}

.profileTypeCard {
  display: flex;
  flex-direction: row;
  padding: 0;
  height: 100%;
}

.profileTypeColour {
  background-color: transparent;
  width: 10px;
  height: auto;
}

.profileTypeCardDisabled {
  background-color: #e6e6e6;
}

.profileTypeContent {
  display: flex;
  flex-grow: 1;
  align-items: center;
  color: #343a40;
  padding: 15px;
}

.profileTypeContentDisabled {
  color: #848484
}

.profileTypeCard:hover {
  background-color: #c6ddf7;
  cursor: pointer;
}

.profileTypeCardSelected {
  background-color: #c6ddf7;
}

.profileTypeText {
  flex-grow: 1;
}

.profileTypeHeading {
  margin-left: 10px;
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 0;
  overflow: hidden;
  word-break: break-word;
  hyphens: auto;
}

.parentTypeHeading {
  margin-left: 20px;
  margin-top: 3px;
  font-size: 0.9rem;
  color: gray;
  margin-bottom: 0;
  overflow: hidden;
  word-break: break-word;
  hyphens: auto;
}

.description {
  margin-top: 5px;
  margin-left: 20px;
  font-size: 0.9rem;
  color: gray;
}

.parentTypeName {
  font-weight: 600;
}

.dragHandle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 10px;
  cursor: move;
}

.dragHandleIcon {
  color: #848484;
  font-size: 1.3rem;
}
