.activity {
  padding: 0.5rem;
  border-radius: 5px;
  overflow: hidden;
  width: 100%;
}

.activityHeader {
  display: flex;
  width: 100%;
  background-color: #f2f2f2;;
  border-radius: 3px;
  padding: 0.5rem;
  justify-content: space-between;
  align-items: center;
}

.activityBody {
  padding: 0.5rem;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.activityBodyContent {
  width: 100%;
}

.activityBodyDetails {
  display: grid;
  grid-template-columns: repeat(auto-fill, calc(50% - 0.5rem));
  grid-gap: 0.5rem;
}

.activityDocuments {
  margin-top: 0.5rem;
}

.tag + .tag {
  margin-left: 0.25rem;
}

.documentIcon {
  margin-left: 0.25rem;
}

@media (min-width: 1024px) {
  .activityBody {
    flex-direction: row;
  }

  .activityBodyDetails {
    grid-template-columns: repeat(auto-fill, calc(25% - 0.5rem));
  }
}


/* Incidents */
.activityIncident .activityBodyContent {
  width: 100%;
}

.activityIncident .activityBodyDetails {
  grid-template-columns: repeat(auto-fill, calc(50% - 0.5rem));
  margin-right: 1rem;
}

.activityNotes {
  width: 100%;
  overflow: hidden;
  margin-top: 0.5rem;
}

.activityNotesText {
  height: 90%;
  padding: 0.25rem;
}

@media (min-width: 1024px) {
  .activityIncident .activityBodyContent, .activityNotes {
    width: 50%
  }

  .activityNotes {
    margin-top: 0;
  }
}
