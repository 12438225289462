.pageContainer {
  display: flex;
  height: 88vh;

  display: flex;
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto;
}


.pageContainer > div {
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 10px;
}

.pageContainer > div:first-child {
  flex-grow: 1;
}

.pageContainer > div:last-child {
  margin-right: 10px;
}

.fullSideBar {
  display: flex;
  min-width: 700px;
  justify-content: center;
}

.halfSideBar {
  width: 350px;
  margin-right: 5px;
  padding: 10px;
}
