.verifyContainer {
  display: flex;
  justify-content: space-around;
  width: 100vw;
  height: 100vh;
  min-width: none;
  background-color:#118ab9;
}

.verifyContent {
  background-color: white;
  height: 100vh;
  box-shadow: 0px 0px 10px 10px rgba(136,136,136,0.5);
  width: 100%;
  max-width: 800px;
  min-width: 400px;
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  font-size: 1.2rem;
}

.logo {
  width: 300px;
  margin: 20px auto;
}

.verifyButtons {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.verifyButtons Button {
  margin: 10px
}
