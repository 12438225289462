.currentIcon {
  width: 48px;
  height: 48px;
  margin-right: 10px;
  cursor: pointer;
}

.currentIconDisabled {
  cursor: not-allowed;
}

.iconSelectorContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.availableIcon {
  margin: 5px;
  padding: 2px;
  border: 5px solid transparent;
  cursor: pointer;
  width: 72px;
  height: 72px;
}

.availableIcon:hover {
  border: 5px solid #137cbd;
}
