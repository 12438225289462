.ordersButton {
  color: #048abb;
}

.ordersButton:hover {
  cursor: pointer;
}

.buttonsContainer {
  display: flex; 
  flex-direction: column;
  gap: 0.5em;

  @media (min-width: 1200px) {
    flex-direction: row;
  }
}