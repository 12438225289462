.result {
  padding: 0.5rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  cursor: pointer;
  overflow: auto;
  background-color: #F2F2F2;
  border: 1px solid #F2F2F2;
  border-radius: 5px;
}

.result:hover {
  background-color: rgba(0, 123, 255, 0.2);
  border: 1px solid #137cbd;
}

.result:hover .resultHeader {
  color: #137cbd;
}

.result:hover .icon {
  color: #137cbd;
}

.resultHeader {
  margin: 0px;
  font-weight: 600;
  font-size: 1.2rem;
  word-wrap: break-word;
}

.resultSubheader {
  margin: 0px;
  font-weight: 300;
}

.icon {
  margin-right: 1rem;
}

.sectionHeading {
  font-weight: 600;
  margin: 8px 0 0 0;
  color: #137cbd;
}


.tabList {
  overflow-y: auto;
}
