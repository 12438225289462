.incident {
  width: 100%;
  display: flex;
  align-items: center;

  padding: 0.5rem;
  margin: 10px 10px 0px 10px;

  background-color: #F2F2F2;
  border: 1px solid #F2F2F2;
  border-radius: 5px;
}

.incident:hover {
  cursor: pointer;
  background-color: rgba(0, 123, 255, 0.2);
}

.incidentContent {
  margin-left: 10px;
  width: 100%;
}

.incidentGroup {
  display: flex;
  justify-content: space-between;
}

.incidentGroup > *{
  width: 49%;
}

.incidentTags {
  display: flex;
  justify-content: right;
}

.incidentExtras {
  display: block;
  text-align: right;
}

.tag + .tag {
  margin-left: 0.25rem;
}

.documentIcon {
  margin-top: 0.25rem;
  margin-right: 0;
  margin-left: 0.25rem;
}
