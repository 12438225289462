/* 
This is to get round an issue where we want vertical lines in the grid 
but when the item is selected, this border was taking precedence.
*/
.scr-grid-cell-divider:not(.ag-cell-range-single-cell):not(.ag-cell-range-right) {
  border-right: dotted 1px lightgrey !important;
}

.ag-header-cell-text {
  display: block;
  display: -webkit-box;
  max-height: 100%;
  overflow: hidden;

  line-clamp: 3;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  
  white-space: pre-wrap;      /* CSS3 */   
  white-space: -moz-pre-wrap; /* Firefox */    
}