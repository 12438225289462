
.systemContainer {
  width: 100%;
  border: 2px solid transparent;
  height: 80px;
}

.systemCard {
  display: flex;
  flex-direction: row;
  padding: 0;
  min-height: 0px;
  cursor: pointer;
  height: 100%;
}

.systemColour {
  background-color: transparent;
  width: 15px;
  height: auto;
}

.systemDisabled {
  background-color: #e6e6e6;
}

.buttonHover:hover {
  min-height: 0px;
  background-color: #c6ddf7
}

.buttonSelected {
  background-color: #c6ddf7
}

.systemContent {
  display: flex;
  width: 100%;
  padding: 15px;
  align-items: center;
}

.systemHeading {
  font-size: 1rem;
  font-weight: 600;
  color: #343a40;
  margin-bottom: 0;
  overflow: hidden;
  word-break: break-word;
  hyphens: auto;
  flex-grow: 1;
}

.systemHeadingDisabled {
  color: #848484
}

.dragHandle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 10px;
  cursor: move;
}

.dragHandleIcon {
  color: #848484;
  font-size: 1.3rem;
}
