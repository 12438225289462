.profileTypeContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  cursor: pointer;
}

.contentContainer {
  display: flex;
  flex-direction: column;
  margin-left: 5px;
  width: 100%;
}

.profileName {
  font-size: 18px;
  font-weight: 600;
  margin: 5px 0;
}

.tagsContainer {
  display: flex;
  flex-direction: row;
}