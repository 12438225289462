.libraryContainer {
  overflow-y: scroll;
  height: 100%;
}

.libraryHeader {
  font-weight: 500;
  font-size: 1.2rem;
}

.fieldTypeButton {
  display: flex;
  border: 1px solid #F2F2F2;
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
  margin-bottom: 10px;
  background-color: white;
}

.fieldTypeButton:hover {
  background-color: rgba(0, 123, 255, 0.2);
}

.fieldTypeImageContainer {
  display: block;
  width: 48px;
  height: 48px;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 10px;
}

.fieldTypeImage {
  width: 48px;
  height: 48px;
}

.fieldTypeHeader {
  font-weight: 600;
  font-size: 1rem;
  margin-top: 5px;
  margin-bottom: 2px;
}

.fieldTypeDescription {
  margin-bottom: 0;
}

.reactDndCopy ~ div {
  transform: none !important;
}

.reactDndCopy ~ [data-rbd-placeholder-context-id] {
  display: none !important;
}