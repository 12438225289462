.organisationOption {
  width: 100%;
  display: flex;
  align-items: center;

  padding: 0.5rem;
  margin: 10px 10px 0px 10px;

  cursor: pointer;
  background-color: #F2F2F2;
  border: 1px solid #F2F2F2;
  border-radius: 5px;
}

.organisationOptionLast {
  margin: 10px;
}

.organisationOption:hover {
  background-color: rgba(0, 123, 255, 0.2);
}

.currentOrganisation {
  color: #137cbd;
}

.organisationHeader {
  margin: 0;
  margin-left: 5px;
  font-size: 1rem;
  font-weight: 500;
}

.scoresContainer {
  display: flex;
  flex-direction: column;
  margin: 5px;
}

.organisationScore {
  font-size: 0.9rem;
  font-weight: 500;
  margin: 0;
  margin-left: 10px;
  text-align: right;
} 
