
.mergeTable {
  width: 100%;
  background-color: white;
}

.mergeDialog {
  width: 800px
}

.mergeConflictRow {
  background-color: #fdf7d8
}