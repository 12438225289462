.tabPanel {
	z-index: 2;
	position: relative;
}
/* 
.tab {
  display: inline-block;
  border: 1px solid transparent;
  border-bottom: none;
  bottom: -1px;
  position: relative;
  list-style: none;
  padding: 6px 12px;
  cursor: pointer;
} */

.tabSelected {
  background: #fff;
  border-color: #aaa;
  color: black;
  border-radius: 5px 5px 0 0;
}

.tabPanelSelected {
	padding: 10px;
	z-index: 2;
	position: relative;
  display: block;
  background-color: #eeeeee;
  border: 1px solid;
  border-color: #aaa;
}

.tabList {
  margin: 0;
  padding: 0;
}

.validationErrorText {
  color: red;
  margin-top: -12px;
  margin-bottom: 12px;
}