.buttonContainer {
  width: 100%;
}

.buttonCard {
  display: flex;
  flex-direction: column;
  padding: 0;
  cursor: pointer;
  justify-content: center;
  align-items: center;
}

.enabledButton {
  cursor: pointer;
}

.enabledButton:hover {
  background-color: #c6ddf7
}

.plusIcon {
  color: #343a40;
  font-size: 2rem;
}
