.list {
  background-color:white;
}

.listBorders {
  border: 1px solid #d9dddd;
}

.listItem {
  display: flex;
  align-items: stretch;
  flex-grow: initial;
  overflow:hidden;
}

.listItemBorders {
  border-bottom: 1px solid #d9dddd;
}

.profileContainer {
  width: 100%;
  display: flex;
  align-items: center;
  
  padding: 0.5rem;
  margin: 10px 10px 0px 10px;

  cursor: pointer;
  background-color: #F2F2F2;
  border: 1px solid #F2F2F2;
  border-radius: 5px;
}

.profileContainerLast {
  margin: 10px;
}