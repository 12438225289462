.signOffItem {
  display: flex;
  width: 100%;
  display: flex;
  align-items: center;

  padding: 10px 15px;
  margin: 10px 10px 0px 10px;

  background-color: #F2F2F2;
  border: 1px solid #F2F2F2;
  border-radius: 5px;
}

.detailsList {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-left: 15px;
}

.signOffDate {
  flex-wrap: nowrap;
  flex-shrink: 0;
  margin-left: 15px
}
