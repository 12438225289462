.headerContainer {
  display: flex;
  flex-direction: column;
}

.headerOrganisationName {
  text-transform: uppercase;
  font-weight: 500;
  margin: 0;
  padding: 0 5px;
}

.selectorContainer {
  display: flex;
  align-items: center;
  padding: 2px 5px;
  cursor: pointer;
}

.selectorContainer:hover {
  background-color: #cfe2fc;
  border-radius: 5px;
}

.selectorCurrent {
  margin: 0;
}

.selectorButton {
  margin-left: 1rem;
  font-size: 18px;
}

.popoverContainer {
  width: 400px;

  padding: 0.5rem 1rem 1rem 1rem;
}

.groupHeader {
  margin: 10px 0;
  text-transform: uppercase;
  font-weight: 600;
}

.dashboardItemContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.5rem;
  margin: 0.3rem 0rem;
  cursor: pointer;
  background-color: #F8F8F8;
  border: 2px solid #F8F8F8;
  border-radius: 5px;
  overflow: hidden;
}

.dashboardItemContainer:hover {
  background-color: rgba(0, 123, 255, 0.2);
  border: 2px solid #137cbd;
}

.dashboardItem  {
  flex-grow: 1;
  margin: 0px 5px 0px 5px;
  background-color: transparent;
  overflow: hidden;
}

.dashboardItem > p {
  margin: 0;
  font-weight: 400;
  font-size: 1.1rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
