.menuItem {
  display: block;
  padding: 0.5rem 1rem;
  cursor: pointer;
}

.active {
  font-weight: bold;
}

.groupContainer {
  display: flex;
}

.rowSeparator {
  border-top-style: solid;
  border-top-width: 1px;
}

.headerCell {
  padding: 4px;
  color: #048abb;
  font-weight: 700;
  font-size: 14px;
}

.cell {
  padding: 4px;
  width: 33%;
  text-align: left;
  vertical-align: top;
}

.table {
  background-color: white;
  border-color: black;
  /* margin: 20px; */
  border-style: solid;
  border-color: lightgray;
  border-width: 1px;
  width: 100%;
  margin-top: 20px;
}

.alternateRow {
  background-color: #f1f1f1;

}