.filtersContainer {
  margin: 0 10px 15px 10px;
}

.documentsContainer {
  padding: 20px 10px
}

.documentsCard {
  min-height: 400px;
  width: 100%;
}

.documentItem {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  padding: 5px 10px;
}

.documentItem:last-child {
  margin-bottom: 0;
}

.documentItem:hover {
  border-radius: 5px;
  background-color: rgba(0, 123, 255, 0.2);
}

.documentIcon {
  color: #137cbd;
}

.documentDetailsContainer {
  margin: 0 10px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  flex-grow: 1;
}

.documentDetails {
  margin-right: 20px;
}

.documentDetails > p {
  margin-bottom: 0;
}

.documentUploadDetails > p {
  margin-bottom: 0;
  text-align: end;
}

.documentName {
  font-weight: 600;
}

.documentButtons {
  display: flex;
  align-self: center;
}

.dropzonesContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.dropzone {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 150px;
}

.dropzoneText {
  margin-top: 10px;
  font-weight: 500;
  flex-grow: 1;
}

.dropzoneActive {
  background-color: rgba(0, 123, 255, 0.2);
}
