.overviewCardContainer {
  outline: none;
}

.overview {
  padding: 0;
  min-height: 330px;
  display: flex;
  flex-direction: column;
}

.overviewContainer {
  display: flex;
  padding: 15px;
  flex-direction: column;
  flex-grow: 1;
}

.profileImage {
  display: block;
  min-width: 50px;
  min-height: 50px;
  max-width: 150px;
  max-height: 150px;
  /* width: 100%; */
  height: auto;
  margin: 20px auto 10px auto;
  border-radius: 10px;
}

.profileTypeDetails {
  display: flex;
  cursor: pointer;
  user-select: none;
  align-items: center;
  padding: 5px;
}

.profileTypeDetails:hover {
  background-color: rgba(0, 123, 255, 0.2);
  border-radius: 5px;
}

.profileTypeImage {
  width: 40px;
  height: 40px;
  margin-right: 5px;
}

.profileType {
  margin: 0;
  font-weight: 600;
}

.organisationName {
  margin: 0;
}

.editButton {
  margin-left: 20px;
  margin-bottom: auto;
  height: 10px;
}

.profileName {
  font-size: 1.5rem;
  font-weight: 600;
  color: #343a40;
  text-align: center;
}

.field {
  border-bottom: 1px solid lightgray;
  color: #343a40;
  font-size: 1rem;
  font-weight: 300;
}

.fieldName {
  font-weight: 400;
  padding-top:5px;
}

.fieldValue {
  text-align: right;
  padding-top:5px;
}

.dropzoneActive {
  background-color: rgba(0, 123, 255, 0.2);
}

.tags {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
}
