.filterContainer {
  display: inline-flex;
  align-items: center;
}

.filterText {
  font-size: 1.1rem;
  cursor: default;
  user-select: none;
  margin: 0;
}

.filterOption {
  display: inline-flex;
  align-items: center;
  flex-wrap: wrap;
  font-weight: 600;
  cursor: pointer;
  padding: 5px 0px;
}

.filterOption:hover {
  background-color: rgba(0, 123, 255, 0.2);
  border-radius: 5px;
}

.selectionMenu {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.menuButtons {
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 10px;
}
