.messageValueContainer {
  display: table;
  table-layout: fixed;
  width: 100%;
}

.messageValue {
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
