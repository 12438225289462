.userProfileContainer {
  margin-top: 15px;
  max-width: 1600px;
}

.overviewsContainer > div {
  margin-right: 10px;
  margin-bottom: 20px;
}

.sectionsContainer {
  display: flex;
  flex-wrap: wrap;
}

.sectionsContainer > div {
  margin: 0 0 20px 0;
}

@media (min-width: 1200px) {
  .overviewsContainer > div {
    margin-right: 20px;
  }

  .sectionsContainer > div {
    margin-bottom: 20px;
    flex: 0 0 calc(50% - 10px);
  }
  .sectionsContainer > div:nth-child(even) {
    margin-left: 10px;
  }
  .sectionsContainer > div:nth-child(odd) {
    margin-right: 10px;
  }
}

.profileScores {
  padding: 0;
  margin-bottom: 20px;
}
