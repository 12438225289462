.profileSummaryContainer {
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  align-items: center;
  justify-content: space-between;
}

.profileTypeContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  height: 120px;
  width: 310px;
  cursor: pointer;
}

.contentContainer {
  display: flex;
  flex-direction: column;
  margin-left: 5px;
}

.profileName {
  font-size: 18px;
  font-weight: 600;
  margin: 5px 0;
}

.tagsContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
