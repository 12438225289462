.lockableDecoratorContainer {
  display: flex;
  align-items: center;
}

.decoratedComponentWrapper {
  flex-grow: 1;
}

.lockButton {
  margin-left: 10px;
}
