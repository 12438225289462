.sectionContainer {
  width: 100%;
  outline: none;
}

.sectionCard {
  display: flex;
  flex-direction: column;
  min-height: 175px;
  padding: 0;
}

.section {
  user-select: none;
  display: flex;
  padding: 15px;
  flex-grow: 1;
}

.sectionWrapper {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
}

.sectionColour {
  background-color: transparent;
  width: 10px;
  height: auto;
}

.sectionContent {
  display: flex;
  width: 100%;
}

.sectionLogoGroup {
  display: flex;
  flex-direction: column;
}

.sectionLogo {
  width: 70px;
  height: 70px
}

.mainContent {
  flex-grow: 1;
}

.sectionHeading {
  margin-top: 5px;
  margin-left: 15px;
  font-size: 1.2rem;
  font-weight: 600;
  color: #343a40;
  word-break: break-word;
  hyphens: auto;
}

.fieldName {
  font-weight: 400;
  margin-right: 2px;
}

.buttons {
  margin-bottom: auto;
  margin-left: 10px;
}

.button {
  height: 10px;
}

.documents {
  margin-top: 10px;
  margin-left: 5px;
  margin-right: 5px;
  cursor: pointer;
}

.dropzone {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  margin-left: -60px;
}

.dropzoneText {
  margin-top: 10px;
  font-weight: 500;
}

.dropzoneActive {
  background-color: rgba(0, 123, 255, 0.2);
}
