.dashboardListContainer {
  padding: 0;
  margin: 0;
  list-style-type: none;
  height: 100%;
}

.dashboardLayoutContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 10px auto;
}

.dashboardLayoutContainer > li {
  margin: 10px;
}

.blankDashboardContainer {
  height: 60vh;
}
