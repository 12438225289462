.colorPickerContainer {
  display: flex;
  align-items: center;
}

.colorPreview {
  width: 40px;
  height: 40px;
  margin-right: 10px;
  border: 5px solid transparent;
  border-radius: 5px;
}

.sliderPicker {
  flex-grow: 1;
}
