.filterBar {
  display: flex;
  padding: 5px 20px;
  justify-content: space-between;
  align-items: center;
}

.groupContainer {
  margin-top: 10px;
}

.profileContainer {
  width: 100%;
  display: flex;
  align-items: center;
  
  padding: 0.5rem;
  margin: 10px 10px 0px 10px;

  cursor: pointer;
  background-color: #F2F2F2;
  border: 1px solid #F2F2F2;
  border-radius: 5px;
}

.profileContainer:hover {
  background-color: rgba(0, 123, 255, 0.2);
}

.profileContainerLast {
  margin: 10px;
}

.profileName {
  margin: 0;
  margin-left: 2px;
  font-size: 1rem;
  font-weight: 500;
}

.scoresContainer {
  display: flex;
  flex-direction: column;
  margin: 5px;
  border-style: none;
}

.profileScore {
  font-size: 0.9rem;
  font-weight: 500;
  margin: 0;
  margin-left: 10px;
  text-align: right;
}

.tagsContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.tabBadge {
  margin-left: 5px;
  min-width: 30px;
  text-align: center;
}

.tabButton {
  flex-grow: 1;
  max-width: 200px;
}

.editButton {
  flex-grow: 1;
}


